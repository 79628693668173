<template>
    <b-card no-body class="mb-1" id="assesment">
    <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block @click.stop.prevent="toggleCardCollapse($event, 'assessment')" variant="false" class="text-left">
        <div class="label_code mr-1">
            <h2>A</h2>
        </div><strong>Assessment</strong>
        <span class="when-opened">
            <i class="icon-arrow-up12"></i>
        </span>
        <span class="when-closed">
            <i class="icon-arrow-down12"></i>
        </span>
        </b-button>
    </b-card-header>
    <div class="mt-2" role="tabpanel">
        
        <div class="col-md">
        <div class="row">
            <div class="col-md-6">
            <div class="card border shadow-0">
                <div class="bg-light card-header py-2">
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                    <a href="javascript:;" v-if="isParent.riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                    <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                    </a>
                </div>
                </div>
                <div class="card-body py-2">
                  <div class="result_tab">
                    <h4>Diagnosis Triase</h4>
                    <p>{{isParent.row.diagnosis_triase||"-"}}</p>
                  </div>
                </div>
                <div class="card-body">
                <div class="form-group mb-0">
                    <div class="input-group">
                    <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                        <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                            v-model="inputICD10"
                            @search="filterICD10($event,0)"
                            @input="selectICD10(inputICD10)"
                            :options="isParent.mICD10" label="text"
                            :clearable="true" :reduce="v=>v.value">
                            
                            <template slot="selected-option">
                            <span v-b-tooltip.hover.right :title="inputICD10Name">
                                {{inputICD10Name||"-"}}
                            </span>
                            </template>
                            
                            <template slot="option" slot-scope="option">
                            <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                            </template>
                            <template #list-footer v-if="(isParent.mICD10||[]).length >= 1">
                            <li class="list-paging">
                                <div class="d-flex align-items-center justify-content-between">
                                <b-button @click="changePageICD10(isParent.mICD10[0].currentPage,0,'min','mICD10')"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                    class="icon-arrow-left22 text-white"></i></b-button>
                                <small>Halaman {{isParent.mICD10[0].currentPage}} dari
                                    {{ceilData(isParent.mICD10[0].totalRows/5)}}</small>
                                <b-button @click="changePageICD10(isParent.mICD10[0].currentPage,0,'plus','mICD10')"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                    class="icon-arrow-right22 text-white"></i></b-button>
                                </div>
                            </li>
                            </template>
                        </v-select>
                    </div>
                    </div>
                </div>
                </div>
                <table class="table table-bordered table-sm" v-if="(isParent.dataICD10||[]).length">
                <thead>
                    <tr>
                    <th>Item ICD-10 dipilih</th>
                    <th width="64">Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in (isParent.dataICD10||[])" :key="k">
                    <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                    <td>
                        <a href="javascript:;" @click="removedataICD10(v.auicd10d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                        <i class="icon-trash-alt"></i>
                        </a>
                    </td>
                    </tr>
                </tbody>
                </table>
                <div class="card-body py-2" v-else>
                <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                </div>
                <div class="card-body pt-2">
                <div class="form-group mb-0">
                    <label for="inputManualDiagnose">
                    Diagnosa Lainnya <strong class="txt_mandatory" v-if="!(isParent.dataICD10||[]).length">*</strong>
                    <span style="cursor:help;" data-popup="tooltip" title="Silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                    </label>
                    <b-textarea v-model="isParent.row.auod_diagnosa" name=""
                    id="" rows="3" class="form-control"></b-textarea>
                    
                <VValidate v-if="!(isParent.dataICD10||[]).length" :name="`Diagnosa`" v-model="isParent.row.auod_diagnosa"
                    :rules="{required:1, min:2, max:512}" />
                </div>
                </div>
            </div>
            </div>
            
            <div class="col-md-6">
            <div class="card border shadow-0">
                <div class="bg-light card-header py-2">
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                    <a href="javascript:;" v-if="isParent.riwayatICD9.length" @click="openriwayatICD9 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                    <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                    </a>
                </div>
                </div>
                <div class="card-body">
                <div class="form-group mb-0">
                    <div class="input-group">
                    <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                        <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                            v-model="inputICD9"
                            @search="filterICD9($event,0)"
                            @input="selectICD9(inputICD9)"
                            :options="isParent.mICD9" label="text"
                            :clearable="true" :reduce="v=>v.value">
                            
                            <template slot="selected-option">
                            <span v-b-tooltip.hover.right :title="inputICD9Name">
                                {{inputICD9Name||"-"}}
                            </span>
                            </template>
                            
                            <template slot="option" slot-scope="option">
                            <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                            </template>
                            <template #list-footer v-if="(isParent.mICD9||[]).length >= 1">
                            <li class="list-paging">
                                <div class="d-flex align-items-center justify-content-between">
                                <b-button @click="changePageICD9(isParent.mICD9[0].currentPage,0,'min','mICD9')"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                    class="icon-arrow-left22 text-white"></i></b-button>
                                <small>Halaman {{isParent.mICD9[0].currentPage}} dari
                                    {{ceilData(isParent.mICD9[0].totalRows/5)}}</small>
                                <b-button @click="changePageICD9(isParent.mICD9[0].currentPage,0,'plus','mICD9')"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                    class="icon-arrow-right22 text-white"></i></b-button>
                                </div>
                            </li>
                            </template>
                        </v-select>
                    </div>
                    </div>
                </div>
                </div>
                <table class="table table-bordered table-sm" v-if="(isParent.dataICD9||[]).length">
                <thead>
                    <tr>
                    <th>Item ICD-9 dipilih</th>
                    <th width="64">Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in (isParent.dataICD9||[])" :key="k">
                    <td>
                    <span>{{v.mi9_name||"-"}}<span class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span>
                    <div v-if="v.mi9_informed_consent"><small class="font-weight-medium text-info-800">Tindakan memerlukan informed consent</small></div>
                    </td>
                    <td>
                        <a href="javascript:;" @click="removedataICD9(v.auicd9d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Data ICD 9">
                        <i class="icon-trash-alt"></i>
                        </a>
                        
                        <a href="javascript:;" @click="openInformConsent(v)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo"  v-b-tooltip.hover :title="v.auicd9d_informed_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                        <i class="icon-file-check2"></i>
                        </a>

                        <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.auicd9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                        <i class="icon-file-eye2"></i>
                        </a>
                    </td>
                    </tr>
                </tbody>
                </table>
                <div class="card-body py-2" v-else>
                <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                </div>
                <div class="card-body pt-2">
                <div class="form-group mb-0">
                    <label for="inputManualDiagnose">
                    Tindakan Lainnya
                    <span style="cursor:help;" data-popup="tooltip" title="Silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                    </label>
                    <b-textarea v-model="isParent.row.auod_tindakan" name=""
                    id="" rows="3" class="form-control"></b-textarea>
                    
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>


    
    <b-modal v-model="openriwayatICD10" :title="'Pilih ICD10 dari Riwayat Pasien'" size="xl" hide-footer>
      <div class="modal-body p-0">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <div class="d-flex w-100">
                <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                  <input v-model="searchICD10" @input="searchICD10Data" class="form-control"
                    placeholder="Ketik Nama Dokter / No. Registrasi" />
                  <div class="form-control-feedback">
                    <i class="icon-search4 text-indigo"></i>
                  </div>
                </div>
                <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm patient-table">
            <thead>
              <tr>
                <th width="64">#</th>
                <th>No. Registrasi</th>
                <th>Tanggal</th>
                <th>Dokter</th>
                <th>ICD10</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(v,k) in isParent.riwayatICD10">
                <tr :class="selectedICD10 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <div class="custom-control custom-control-inline custom-checkbox">
                      <input type="radio" :value="v.arm_id" v-model="selectedICD10" name="historySelectionDiagnose"
                        :id="`histdiag${k+1}`" class="custom-control-input">
                      <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                    </div>

                  </td>

                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                  </td>
                  <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                  <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                      <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                  <td>{{v.mi10_name||"-"}},{{v.api10_diagnosis||"-"}}</td>
                </tr>
              </template>
              <tr v-if="!(isParent.riwayatICD10||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3">
        <div class="text-right">
          <button data-dismiss="modal" @click="openriwayatICD10 = false" class="btn">Batal</button>
          <button data-dismiss="modal" @click="saveICD10" :disabled="!selectedICD10"
            class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
            Pilihan</button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="openriwayatICD9" :title="'Pilih ICD9 dari Riwayat Pasien'" size="xl" hide-footer>
      <div class="modal-body p-0">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <div class="d-flex w-100">
                <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                  <input v-model="searchICD9" @input="searchICD9Data" class="form-control"
                    placeholder="Ketik Nama Dokter / No. Registrasi" />
                  <div class="form-control-feedback">
                    <i class="icon-search4 text-indigo"></i>
                  </div>
                </div>
                <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm patient-table">
            <thead>
              <tr>
                <th width="64">#</th>
                <th>No. Registrasi</th>
                <th>Tanggal</th>
                <th>Dokter</th>
                <th>ICD9</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(v,k) in isParent.riwayatICD9">

                <tr :class="selectedICD9 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <div class="custom-control custom-control-inline custom-checkbox">
                      <input type="radio" :value="v.arm_id" v-model="selectedICD9" name="historySelectionDiagnose"
                        :id="`histdiag${k+1}`" class="custom-control-input">
                      <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                    </div>

                  </td>

                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                  </td>
                  <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                  <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                      <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                  <td>{{v.mi9_name||"-"}},{{v.api9_tindakan||"-"}}</td>
                </tr>
              </template>
              <tr v-if="!(isParent.riwayatICD9||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3">
        <div class="text-right">
          <button data-dismiss="modal" @click="openriwayatICD9 = false" class="btn">Batal</button>
          <button data-dismiss="modal" @click="saveICD9" :disabled="!selectedICD9"
            class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
            Pilihan</button>
        </div>
      </div>
    </b-modal>

    <validation-observer ref="VFormInformconsent">
    <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{rowIC.mi9_name}} <span class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td class="input-cell">
              <textarea v-model="rowIC.auic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
        </tbody>
        <tfoot>
            <tr>
              <td width="50%" colspan="2">
                <div>
                  <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                  <div>
                    <b-form-radio-group
                        :options="Config.mr.pasienSetuju"
                        v-model="rowIC.auic_is_pasien_setuju"
                    />
                    <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.auic_is_pasien_setuju" :rules="{required:1}" />
                  </div>
                </div>
              </td>
              <td width="50%">
                <div>
                  <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                  <div>
                    <b-form-radio-group
                        :options="Config.mr.persetujuanTindakan"
                        v-model="rowIC.auic_persetujuan_tindakan"
                    />
                    <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.auic_persetujuan_tindakan" :rules="{required:1}" />
                  </div>
                </div>
              </td>
            </tr>
        </tfoot>
      </table>
    </b-modal>
    </validation-observer>

    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
          <thead>
            <tr>
              <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
              </td>
            </tr>
            <tr>
              <th width="24">No.</th>
              <th>Jenis Informasi</th>
              <th>Isi Informasi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
              <td>
               {{rowIC.auic_diagnosis||"-"}}
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Dasar Diagnosis</td>
              <td>
               {{rowIC.auic_dasar_diagnosis||"-"}}
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Tindakan Kedokteran</td>
              <td>
               {{rowIC.auic_tindakan_dokter||"-"}}
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Indikasi Tindakan</td>
              <td>
               {{rowIC.auic_indikasi_tindakan||"-"}}
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Tata Cara</td>
              <td>
               {{rowIC.auic_tata_cara||"-"}}
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Tujuan</td>
              <td>
               {{rowIC.auic_tujuan||"-"}}
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Risiko</td>
              <td>
               {{rowIC.auic_risiko||"-"}}
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Komplikasi</td>
              <td>
               {{rowIC.auic_kompilasi||"-"}}
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Prognosis</td>
              <td>
               {{rowIC.auic_prognosis||"-"}}
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>Alternatif & Risiko</td>
              <td>
               {{rowIC.auic_alternatif||"-"}}
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>Lain-lain</td>
              <td>
               {{rowIC.auic_lainnya||"-"}}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td width="50%" colspan="2">
                <div class="result_tab">
                  <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                  <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                  <p v-else>Tindakan ditolak</p>
                </div>
              </td>
              <td width="50%">
                <div class="result_tab">
                  <h4>Persetujuan tindakan diberikan kepada</h4>
                  <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                  <p v-else>Penanggung Jawab</p>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
    </b-modal>

    </b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')

export default{
    extends: GlobalVue,
    // components:{ 
    //     Datepicker
    // },
    
    data() {
      return {
        openriwayatICD10: false,
        openriwayatICD9: false,
        
        selectedICD9: '',
        selectedICD10: '',
        
        searchICD10: '',
        searchICD9: '',
        
        inputICD9: null,
        inputICD10: null,
        
        inputICD9Name: null,
        inputICD10Name: null,
        searchICD10Name: null,
        searchICD9Name: null,

        rowIC : {
          auic_is_pasien_setuju: null,
          auic_persetujuan_tindakan: null
        },

        openModalIC : false,
        openModalICDetail : false,
      }
    },
    methods: {      
        openInformConsentDetail(v){
            Gen.apiRest('/do/' + 'UGDFormDokter', {
                data: {
                    type: 'get-data-ic',
                    id: v.auicd9d_informed_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name = v.mi9_name
                this.rowIC.mi9_code = v.mi9_code
                this.rowIC.id_icd = v.auicd9d_id
                this.openModalICDetail = true                   
            })
        },
        openInformConsent(v){
            if(!v.auicd9d_informed_consent){              
                this.rowIC.auic_persetujuan_tindakan = null
                this.rowIC.auic_is_pasien_setuju = null

                if(v.mi9_informed_consent){
                    Gen.apiRest('/do/' + 'UGDFormDokter', {
                        data: {
                            type: 'autofill-ic',
                            id: v.mi9_informed_consent,
                        }
                    }, 'POST').then(res => {
                        this.rowIC = res.data.row
                        this.rowIC.mi9_name = v.mi9_name
                        this.rowIC.mi9_code = v.mi9_code
                        this.rowIC.id_icd = v.auicd9d_id
                       
                      
                    })
                }else{
                  this.rowIC.auic_diagnosis = null
                  this.rowIC.auic_dasar_diagnosis = null
                  this.rowIC.auic_tindakan_dokter = null
                  this.rowIC.auic_indikasi_tindakan = null
                  this.rowIC.auic_tata_cara = null
                  this.rowIC.auic_tujuan = null
                  this.rowIC.auic_risiko = null
                  this.rowIC.auic_kompilasi = null
                  this.rowIC.auic_prognosis = null
                  this.rowIC.auic_alternatif = null
                  this.rowIC.auic_lainnya = null
                }

            }else{  
                Gen.apiRest('/do/' + 'UGDFormDokter', {
                    data: {
                        type: 'get-data-ic',
                        id: v.auicd9d_informed_consent,
                    }
                }, 'POST').then(res => {
                    this.rowIC = res.data.row 
                    this.rowIC.mi9_name = v.mi9_name
                    this.rowIC.mi9_code = v.mi9_code
                    this.rowIC.id_icd = v.auicd9d_id                   
                })
                // console.log(edit)
            }

            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.auicd9d_id



            this.openModalIC = true
        },

        submitInformConcent(){
            this.$refs['VFormInformconsent'].validate().then(success => {
                if(success){
                    this.openModalIC = false

                    let data = this.rowIC
                    data.type = 'submit-data-inform-consent'
                    data.auic_aur_id = this.isParent.rowReg.aur_id
                    data.auic_name = this.rowIC.mi9_name + " ("+this.rowIC.mi9_code+")"
                    
                    this.loadingOverlay = true
                    Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.isParent.apiGet()  

                        return this.$swal({
                            title: "Data Informed Consent berhasil disimpan",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            })
        },
        ceilData(value) {
            return Math.ceil(value)
        },
        toValidate(val) {
            return {
            ...val
            }
        },
        selectICD9(e) {
            let index = this.isParent.mICD9.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD9Name =  this.isParent.mICD9[index]['text']
                this.searchICD9Name = null
            }else{
                this.inputICD9Name =  null
            }
            this.addNewICD9()
        },

        selectICD10(e) {
            let index = this.isParent.mICD10.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD10Name =  this.isParent.mICD10[index]['text']
                this.searchICD10Name = null
            }else{
                this.inputICD10Name =  null
            }
            this.addNewICD10()
        },

        filterICD10: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.isParent.dataICD10 || []).length; i++) {
                if (this.isParent.dataICD10[i]['auicd10d_icd_10']) {
                    id.push(this.isParent.dataICD10[i]['auicd10d_icd_10'])
                }
            }
            
            this.searchICD10Name = e

            Gen.apiRest('/do/' + 'UGDFormDokter', {
                data: {
                    type: 'select-icd-10',
                    search: e,
                    id: id
                }
                }, 'POST').then(res => {
                this.isParent.mICD10 = res.data.data
            })
        }, 10),

        changePageICD10(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this.isParent[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this.isParent[col][k]['totalRows'] / 5)) {
                    page += 1
                    this.isParent[col][k]['currentPage'] += 1
                }
            }
            
            let data = {
                type: 'select-paging-icd-10',
                page: page,
                search: this.searchICD10Name
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.mICD10 = res.data.data
                this.getmICD10(this.isParent.dataICD10, k)
            })
        },

        filterICD9: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.isParent.dataICD9 || []).length; i++) {
                if (this.isParent.dataICD9[i]['auicd9d_icd_9']) {
                    id.push(this.isParent.dataICD9[i]['auicd9d_icd_9'])
                }
            }

            this.searchICD9Name = e

            Gen.apiRest('/do/' + 'UGDFormDokter', {
                data: {
                    type: 'select-icd-9',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.isParent.mICD9 = res.data.data
            })
        }, 10),

        changePageICD9(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this.isParent[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this.isParent[col][k]['totalRows'] / 5)) {
                    page += 1
                    this.isParent[col][k]['currentPage'] += 1
                }
            }
            let data = {
                type: 'select-paging-icd-9',
                page: page,
                search: this.searchICD9Name
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.mICD9 = res.data.data
                this.getmICD9(this.isParent.dataICD9, k)
            })
        },

        addNewICD9() {
            if(this.inputICD9){
            // if (!this.isParent.row.isEdit) {
            let data = {
                auicd9d_aurm_id: this.isParent.row.aurm_id,
                auicd9d_icd_9: this.inputICD9,
                type: 'add-icd-9'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.isParent.dataICD9.push(resp.row)
                this.inputICD9 = null
            })
            // } else {
            //   let data = {
            //     auicd9d_aurm_id: this.isParent.row.aurm_id,
            //     auicd9d_id: null
            //   }
            //   this.isParent.dataICD9.push(data)
            // }
            }
        },
        removedataICD9(id, k) {
            // if (!this.isParent.row.isEdit) {
            this.$swal({
                icon: 'warning',
                title: 'Apakah Anda yakin ingin menghapus tindakan ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {
                if (result.value) {
                  let data = {
                      id: id,
                      type: 'remove-icd-9'
                  }
                  Gen.apiRest(
                  "/do/" + 'UGDFormDokter', {
                      data: data
                  },
                  "POST"
                  ).then(() => {
                      this.isParent.dataICD9.splice(k, 1)
                  })
                }
            })

           
            // } else {
            //   this.isParent.dataICD9.splice(k, 1)
            // }
        },

        addNewICD10() {
            if(this.inputICD10){
            // if (!this.isParent.row.isEdit) {
            let data = {
                auicd10d_aurm_id: this.isParent.row.aurm_id,
                auicd10d_icd_10: this.inputICD10,
                type: 'add-icd-10'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.isParent.dataICD10.push(resp.row)
                this.inputICD10 = null
            })
            // } else {
            //   let data = {
            //     auicd10d_aurm_id: this.isParent.row.aurm_id,
            //     auicd10d_icd_10: this.inputICD10,
            //   }
            //   this.isParent.dataICD10.push(data)
            // }
            }
        },
        removedataICD10(id, k) {
            // if (!this.isParent.row.isEdit) {
            let data = {
            id: id,
            type: 'remove-icd-10'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(() => {
            this.isParent.dataICD10.splice(k, 1)
            })
            // } else {
            //   this.isParent.dataICD10.splice(k, 1)
            // }
        },

        getmICD9(diagnosa, k) {
            const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.api9_icd_9
            })
            return _.filter(this.isParent.mICD9, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },

        getmICD10(diagnosa, k) {
            const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.api10_icd_10
            })
            return _.filter(this.isParent.mICD10, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },


        
        searchICD10Data: _.debounce(function () {
            let data = {
                search: this.searchICD10,
                aur_ap_id: this.rowReg.aur_ap_id,
                type: 'search-icd-10'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.isParent.riwayatICD10 = resp.data
            })
        }, 500),

        searchICD9Data: _.debounce(function () {
            let data = {
                search: this.searchICD9,
                aur_ap_id: this.rowReg.aur_ap_id,
                type: 'search-icd-9'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.isParent.riwayatICD9 = resp.data
            })
        }, 500),


        saveICD10() {
            if (this.selectedICD10) {
            let data = {
                id: this.selectedICD10,
                aurm_id: this.isParent.row.aurm_id,
                type: 'save-riwayat-icd-10'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: data
                },
                "POST"
            ).then(() => {
                this.openriwayatICD10 = false
                this.resetData()
            })
            }
        },

        saveICD9() {
            if (this.selectedICD9) {
            let data = {
                id: this.selectedICD9,
                aurm_id: this.isParent.row.aurm_id,
                type: 'save-riwayat-icd-9'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: data
                },
                "POST"
            ).then(() => {
                this.openriwayatICD9 = false
                this.resetData()
            })
            }
        },

        
        resetData() {
            this.searchICD10 = '',
            this.searchICD9 = '',
            this.isParent.apiGet()
        },
    },

    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
    }
}

</script>

        
        